/**
 * dataTransformers.js
 * Utility functions for transforming and calculating metrics from fine-tuning run data.
 * Includes functions for calculating improvements and transforming raw run data into
 * a format suitable for display in the dashboard.
 */

/**
 * Calculate the improvement percentage between two scores
 * @param {number|string} newScore - The new (fine-tuned) score
 * @param {number|string} oldScore - The original score
 * @returns {number} - Percentage improvement, returns 0 for invalid/undefined cases
 */
export function calculateImprovement(newScore, oldScore) {
  // Convert to numbers and handle null/undefined
  const newScoreNum = typeof newScore === 'string' ? parseFloat(newScore) : Number(newScore);
  const oldScoreNum = typeof oldScore === 'string' ? parseFloat(oldScore) : Number(oldScore);
  
  // Handle all edge cases
  if (newScoreNum === null || oldScoreNum === null || 
      isNaN(newScoreNum) || isNaN(oldScoreNum)) {
    console.log('Invalid scores, returning 0:', { newScore, oldScore });
    return 0;
  }
  
  // If both scores are 0, there's no improvement (0%)
  if (oldScoreNum === 0 && newScoreNum === 0) {
    console.log('Both scores are 0, returning 0% improvement');
    return 0;
  }
  
  // If old score is 0 but new score isn't, calculate percentage based on new score
  if (oldScoreNum === 0) {
    console.log('Old score is 0, using absolute new score as improvement');
    return newScoreNum > 0 ? 100 : -100; // Consider it 100% improvement if positive, -100% if negative
  }
  
  // Normal case - calculate percentage improvement
  const improvement = ((newScoreNum - oldScoreNum) / Math.abs(oldScoreNum)) * 100;
  console.log('Calculated improvement:', { newScore, oldScore, improvement });
  
  return improvement;
}

/**
 * Transform a single run's raw data into a structured format for display
 * @param {Object} runData - Raw data for a single fine-tuning run
 * @returns {Object} - Transformed run data with calculated metrics
 */
function transformRunData(runData) {
  console.log('Raw Run Data:', JSON.stringify(runData, null, 2));

  const { AI_Eval, STD_Eval, tuning_Info } = runData;

  let highest = [];
  let lowest = [];
  let average_new_AI_Evals = 0;  // Initialize to 0 instead of null
  let average_old_AI_Evals = 0;  // Initialize to 0 instead of null
  let overall_improvement = 0;    // Initialize to 0 instead of null

  // Process AI_Scores FROM FIREBASE, used to plot Model Performance Analysis
  const availableScores = Object.keys(AI_Eval?.AI_Scores || {});
  if (availableScores.length > 0) {
    let newSum = 0;
    let oldSum = 0;
    let validScores = 0;
    
    availableScores.forEach(score => {
      const newValue = parseFloat(AI_Eval.AI_Scores[score].new);
      const oldValue = parseFloat(AI_Eval.AI_Scores[score].old);
      
      if (!isNaN(newValue) && !isNaN(oldValue)) {
        newSum += newValue;
        oldSum += oldValue;
        validScores++;
      }
    });
    
    // Only calculate averages if we have valid scores
    if (validScores > 0) {
      average_new_AI_Evals = newSum / validScores;
      average_old_AI_Evals = oldSum / validScores;
      overall_improvement = calculateImprovement(average_new_AI_Evals, average_old_AI_Evals);
    }
  }

  // Handle potentially null/undefined values for losses
  const trainLoss = STD_Eval?.train_loss !== undefined ? parseFloat(STD_Eval.train_loss) : 0;
  const evalLoss = STD_Eval?.eval_loss !== undefined ? parseFloat(STD_Eval.eval_loss) : 0;

  return {
    name: tuning_Info?.creation_name || "Unnamed Run",
    modelSaving: tuning_Info?.saved_format || "sss",
    training_location: tuning_Info?.training_location || "None",
    model: tuning_Info?.model || "Unknown Model",
    status: tuning_Info?.status || "Pending",
    trainingTimeSeconds: STD_Eval?.train_time || 0,
    train_time: STD_Eval?.train_time
      ? `${Math.floor(STD_Eval.train_time / 3600)}h ${Math.floor((STD_Eval.train_time % 3600) / 60)}m ${Math.floor((STD_Eval.train_time % 60))}s` 
      : "NONE",
    dataset: tuning_Info?.dataset_name || "Unknown Dataset",
    datasetType: tuning_Info?.dataset_type || "Unknown Type",
    trainingMode: tuning_Info?.training_mode || "Unknown Mode",
    endResult: tuning_Info?.finish_link,
    run_model: tuning_Info?.inference_script_url || "None",
    trainLoss: isNaN(trainLoss) ? 0 : trainLoss,
    testLoss: isNaN(evalLoss) ? 0 : evalLoss,
    finetuneType: tuning_Info?.fineTuneType || "Not specified",
    quantization: tuning_Info?.quantization || "None",
    memoryUsage: tuning_Info?.memory_usage || "N/A",
    improvement: isNaN(overall_improvement) ? 0 : overall_improvement,
    maxSequenceLength: tuning_Info?.["max_sequence_length"] || "kk",
    generationLength: tuning_Info?.["generation_length"] || "None",
    rank: tuning_Info?.["rank"] || "None",
    loraAlpha: tuning_Info?.["lora_alpha"] || "None",
    weightDecay: tuning_Info?.["weight_decay"] || "None",
    evaluationSplit: tuning_Info?.["evaluation_split"] || "None",
    loggingSteps: tuning_Info?.["logging_steps"] || "None",
    learningRateSchedulerType: tuning_Info?.["learning_rate_scheduler_type"] || "None",
    optimizer: tuning_Info?.["optimizer"] || "None",
    quantizationMethod: tuning_Info?.["Quantization Method"] || "None",
    learningRate: tuning_Info?.["learning_rate"] || "None",
    epochs: tuning_Info?.["Epochs"] || "None",
    highest,
    lowest,
    average_new_AI_Evals: isNaN(average_new_AI_Evals) ? 0 : average_new_AI_Evals,
    average_old_AI_Evals: isNaN(average_old_AI_Evals) ? 0 : average_old_AI_Evals,
    AI_Eval,
    STD_Eval
  };
}

/**
 * Transform raw API data into a structured format for the dashboard
 * @param {Object} apiData - Raw data from the API
 * @returns {Object} - Transformed data organized by user/run
 */
export function transformData(apiData) {
  const transformedData = {};

  // Iterate through each user's data
  Object.entries(apiData).forEach(([userId, userRuns]) => {
    if (userRuns && (userRuns.AI_Eval || userRuns.STD_Eval || userRuns.tuning_Info)) {
      // Handle direct run data
      if (!transformedData[userId]) {
        transformedData[userId] = [];
      }
      transformedData[userId].push(transformRunData(userRuns));
    } else if (userRuns) {
      // Handle nested run data
      Object.entries(userRuns).forEach(([runId, runData]) => {
        if (!transformedData[runId]) {
          transformedData[runId] = [];
        }
        transformedData[runId].push(transformRunData(runData));
      });
    }
  });

  return transformedData;
}