/**
 * LANDING PAGE COMPONENT
 * 
 * Main entry point and marketing page for the Tune application
 * 
 * SECTIONS:
 * 1. Header Navigation
 *    - Logo
 *    - Navigation links (Features, Integrations)
 *    - Call-to-action (Join Waitlist)
 * 
 * 2. Hero Section
 *    - Main headline ("Find, Tune, Deploy")
 *    - Primary call-to-action
 * 
 * 3. Feature Showcase
 *    - Interactive view selector (Find, Tune, Deploy, Evaluate)
 *    - Feature screenshots with descriptions
 *    - Image enlargement functionality
 * 
 * 4. Features Grid
 *    - Model configuration
 *    - Data handling
 *    - Training options
 *    - Evaluation tools
 * 
 * 5. Footer
 *    - Company info
 *    - Quick links
 *    - Resources
 * 
 * STATE MANAGEMENT:
 * {
 *   activeView: string ('Find' | 'Tune' | 'Deploy' | 'Evaluate'),
 *   enlargedImage: string | null,
 *   viewImages: {
 *     [key: string]: {
 *       image: string,
 *       description: string
 *     }
 *   }
 * }
 * 
 * NAVIGATION:
 * - Header links for section navigation
 * - Smooth scroll to sections
 * - Route to login/signup pages
 * 
 * RESPONSIVE DESIGN:
 * - Mobile-first approach
 * - Responsive navigation
 * - Adaptive layouts for different screen sizes
 */

'use client'

import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { motion, useAnimation } from "framer-motion"
import { ChevronRight, X, Database, FileText, Share2, Code, Sparkles, BotMessageSquare, KeyRound, Clock, Lock, ChartBar, Gauge } from 'lucide-react'
import Header from './ui/Header';

export default function Component() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeView, setActiveView] = useState('Find')
  const [enlargedImage, setEnlargedImage] = useState('')
  const controls = useAnimation()
  const containerRef = useRef(null)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCards = 3;

  const partners = [
    {
      name: "Hugging Face",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/telegram-cloud-photo-size-5-6125420901324406351-x-rnqh0OV87f33KCVPHoE64p38DgmXBo.jpg"
    },
    // {
    //   name: "Ollama",
    //   logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/telegram-cloud-photo-size-5-6125420901324406352-x-Zb4zdTstHZxO3zbkjGjH3v8kD6UCqa.jpg"
    // },
    {
      name: "Unsloth",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/150920049%20(1)-JyOVfawHGgQZVUsuxK0iGmnhGVkk8a.png"
    },
    {
      name: "VLLM",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/1-FvTdokOC9uyatU0uwERs9Uzi9NMti9.png"
    },
    {
      name: "MLX",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/telegram-cloud-photo-size-5-6125420901324406359-m-dUPO627bUR5bfdCJAp0mpQA7aRwQSW.jpg"
    },
    // {
    //   name: "GPT4ALL",
    //   logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Pdoc-jXAPmBuMC1Vfc5beyHCfDvak9GJNzh.png"
    // },
    {
      name: "TRL",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/3-x0LZkq7PDfyQNwZvSx5YnG5LifRiXV.png"
    },
    {
      name: "Modal",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm1lBtF19wO60RqDAql5tgZlV7zAbP1Y97Ug&s"
    },
    {
      name: "Google Colab",
      logo: "https://colab.research.google.com/img/colab_favicon_256px.png"
    },
    {
      name: "Axolotl",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/2oHO2949q2KLC24tYTTUUQCGM7K-XQfearQUXNP12JFwD7Q3S6HGTD8LVm.svg"
    }
  ]

  useEffect(() => {
    const animateCarousel = async () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        const contentWidth = partners.length * 320 // Assuming each partner logo takes 320px width

        await controls.start({
          x: [-contentWidth, 0],
          transition: {
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear",
            },
          },
        })
      }
    }

    animateCarousel()
  }, [controls, partners.length])

  const viewImages = {
    Find: {image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-11-03%20at%209.42.28%20PM-pYuyJaI5BLssncMZPQsOXL5Hx1kfq8.png", description: "Use training data from curated datasets or bring your own  "},
    Tune: {
      image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-02%20at%205.55.03%20PM-A9FbUeauI0y1w0OqJU87ijplk41ai0.png", 
      description: "Tune allows teams to build bespoke models in minutes not days. Our algortithm pre-selects the best model and hyperparameters for your use case "
    },
    Evaluate: {image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-02%20at%205.56.55%20PM-E4cWZxvZhiKfTtjHJuM6bkZAJ7Sk2x.png", description: "AI enabled evaluation suite with metrics focused on your use case"},
    // Deploy: {image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-11-03%20at%2010.43.21%20PM-tU4hdsrdCHflVUVePYRlUuRvOmeRpA.png", description: "Bring your models wherever you go 🧳, however you want"},
    
  }

  const handlePlayVideo = () => {
    setIsVideoModalOpen(true);
  };

  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + features.length) % features.length);
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  const features = [
    {
      icon: <KeyRound className="h-8 w-8 text-blue-600 mb-4 flex-shrink-0" />,
      title: "Own Your AI, use your models anywhere",
      description: "Export your models in GGUF or Safetensors formats and enjoy unrestricted usage. Say goodbye to rate limits, token caps, and recurring API costs."
    },
    {
      icon: <FileText className="h-8 w-8 text-blue-600 mb-4 flex-shrink-0" />,
      title: "Integrated Data Handling",
      description: "Seamlessly import data in varying formats and build bespoke language models. We handle the rest."
    },
    {
      icon: <Gauge className="h-8 w-8 text-blue-600 mb-4 flex-shrink-0" />,
      title: "Integrated Evaluation",
      description: "Comprehensive evaluation tools to assess model performance across various tasks and use cases."
    },
    {
      icon: <Sparkles className="h-8 w-8 text-blue-600 mb-4 flex-shrink-0" />,
      title: "Synthetic Data Generation",
      description: "Generate high-quality synthetic data to enhance model performance and reduce data collection and preparation costs."
    },


    {
      icon: <BotMessageSquare className="h-8 w-8 text-blue-600 mb-4 flex-shrink-0" />,
      title: "Model Configuration",
      description: "Our algorithm optimizes model selection and hyperparameters for your specific use case."
    }
  ];

  const getVisibleFeatures = () => {
    const end = currentIndex + visibleCards;
    if (end <= features.length) {
      return features.slice(currentIndex, end);
    }
    return [...features.slice(currentIndex), ...features.slice(0, end - features.length)];
  };

  const handleNavClick = (e, href) => {
    e.preventDefault();
    if (href.startsWith('#')) {
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(href);
    }
  };

  return (
    <div className="min-h-screen bg-blue-50">
      <Header />

      <main className="container mx-auto px-4 text-center pt-24 pb-32">
        <h1 className="text-[4rem] leading-tight font-bold text-blue-900 tracking-tight mb-12">
          Find. Tune. Evaluate.
        </h1>
        <div className="space-y-6 max-w-3xl mx-auto">
          <p className="text-2xl font-medium text-gray-800 leading-relaxed">
            UI-driven, language model Fine-tuning and evaluation.
          </p>
          <p className="text-xl text-blue-600 font-semibold tracking-wide uppercase">
            Own Your AI
          </p>
        </div>
        <a
          href="https://noteforms.com/forms/tune-waitlist-rum4ca"
          className="inline-block mt-12 bg-blue-600 text-white px-8 py-3 rounded-lg text-lg font-medium hover:bg-blue-700 transition-colors"
        >
          Get Early Access →
        </a>
      </main>

      <section className="bg-white py-24">
          <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-16 text-center text-blue-900">Common Fine-tuning Challenges We Solve</h2>
            
            <div className="grid md:grid-cols-3 gap-12 max-w-5xl mx-auto">
              <div className="space-y-4">
                <div className="w-12 h-12 bg-red-100 text-red-600 rounded-lg flex items-center justify-center mb-6">
                  <Lock className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold">Restrictive Platforms</h3>
                <p className="text-gray-600">
                  Break free from rate limits, token restrictions, and vendor-imposed inference costs.<br />
                  <strong>Own your AI, don't rent it.</strong>
                </p>
              </div>
              
              <div className="space-y-4">
                <div className="w-12 h-12 bg-amber-100 text-amber-600 rounded-lg flex items-center justify-center mb-6">
                  <Clock className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold">Time Spent on Data Preparation</h3>
                <p className="text-gray-600">
                  Preparing datasets for fine-tuning is time consuming and different frameworks require different formats. <strong> Tune meets you where you are, and handles the rest.</strong>
                </p>
              </div>
              
              <div className="space-y-4">
                <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-6">
                  <ChartBar className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold">Evaluation Gaps</h3>
                <p className="text-gray-600">
                  Many frameworks lack comprehensive evaluation tools, making it difficult to assess model performance across different tasks and use cases. <strong> Leverage our in-built evaluation suite to assess your models.</strong>
                </p>
              </div>
            </div>
          </div>
        </section>

      <main>


        <section className="py-24 bg-blue-50">
          <div className="container mx-auto px-6">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-16 text-center text-blue-900">
                UI-driven, fine-tuning and evaluation.
              </h2>
              <div className="flex justify-center space-x-6 mb-12">
                {['Find', 'Tune',  'Evaluate'].map((view) => (
                  <button
                    key={view}
                    onClick={() => setActiveView(view)}
                    className={`px-6 py-2.5 rounded-lg text-sm font-medium transition-all ${
                      activeView === view
                        ? 'bg-blue-600 text-white'
                        : 'bg-blue-100 text-blue-600 hover:bg-blue-200'
                    }`}
                  >
                    {view}
                  </button>
                ))}
              </div>
              <div className="bg-blue-50 rounded-2xl overflow-hidden transition-all duration-300 transform hover:shadow-xl">
                <div
                  onClick={() => setEnlargedImage(viewImages[activeView].image)}
                  className="relative overflow-hidden cursor-zoom-in"
                >
                  <img
                    src={viewImages[activeView].image}
                    alt={`${activeView} screenshot`}
                    className="w-full h-auto transition-transform duration-300 hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <p className="text-lg text-blue-700 text-center">
                    {viewImages[activeView].description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 bg-white" id="demo-video">
          <div className="container mx-auto px-3">
            <h2 className="text-3xl font-bold mb-16 text-center text-blue-900">
              Tune in action
            </h2>
            <div className="flex justify-center">
              <div className="w-full max-w-5xl aspect-video rounded-lg shadow-lg">
                <iframe
                  src="https://drive.google.com/file/d/1E0ySc2jrD_61Iq9QtTbpZ16YawlxIzOp/preview" 
                  width="100%"
                  height="100%"
                  allow="autoplay"
                  className="w-full h-full object-cover"
                ></iframe>
              </div>
            </div>
          </div>
        </section>



        <section className="py-24 bg-blue-50" id="features">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-16 text-center text-blue-900">Core Features</h2>
            <div className="relative flex items-center justify-between">
              <button 
                className="bg-white rounded-full p-2 shadow-md hover:shadow-lg transition-shadow"
                onClick={handleLeftClick}
                style={{ marginRight: '1rem' }}
              >
                <ChevronRight className="h-6 w-6 text-blue-600 rotate-180" />
              </button>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {getVisibleFeatures().map((feature, index) => (
                  <div key={index} className="group h-full col-span-1">
                    <div className="bg-white p-6 rounded-2xl shadow-sm group-hover:shadow-md transition-all border border-blue-100 h-full flex flex-col" style={{ height: '300px' }}>
                      {feature.icon}
                      <h3 className="text-lg font-semibold mb-3 text-blue-900 flex-shrink-0">{feature.title}</h3>
                      <p className="text-blue-700 leading-relaxed flex-grow">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="bg-white rounded-full p-2 shadow-md hover:shadow-lg transition-shadow"
                onClick={handleRightClick}
                style={{ marginLeft: '1rem' }}
              >
                <ChevronRight className="h-6 w-6 text-blue-600" />
              </button>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white" id="integrations">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-16 text-center text-blue-900">
              Powered by
            </h2>
            <div className="relative overflow-hidden w-full" style={{ height: '200px' }} ref={containerRef}>
              <motion.div
                className="flex absolute"
                animate={controls}
                style={{
                  width: `${partners.length * 280 * 2}px`,
                }}
              >
                {[...partners, ...partners].map((partner, index) => (
                  <div
                    key={`${partner.name}-${index}`}
                    className="flex flex-col items-center justify-center flex-shrink-0"
                    style={{ width: '280px' }}
                  >
                    <div className="w-32 h-32 rounded-xl shadow-sm flex items-center justify-center p-6 mb-3 bg-blue-200 border border-blue-300">
                      <img
                        src={partner.logo}
                        alt={`${partner.name} logo`}
                        className="w-full h-full object-contain opacity-80 hover:opacity-100 transition-opacity"
                      />
                    </div>
                    <span className="text-sm font-medium text-blue-700">{partner.name}</span>
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
        </section>


      </main>

      <footer className="bg-blue-50 py-16 border-t border-blue-100">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
            <div>
              <h3 className="text-xl font-bold mb-4 text-blue-900">Tune</h3>
              <p className="text-blue-700 leading-relaxed">
                Empowering developers to build the next generation of AI applications.
              </p>
            </div>
            {/* <div>
              <h4 className="text-sm font-semibold uppercase tracking-wider text-blue-900 mb-4">Navigation</h4>
              <ul className="space-y-3">
                <li><a href="#" className="text-blue-600 hover:text-blue-700 transition-colors">Home</a></li>
                <li><a href="#features" className="text-blue-600 hover:text-blue-700 transition-colors">Features</a></li>
                <li><a href="#integrations" className="text-blue-600 hover:text-blue-700 transition-colors">Integrations</a></li>
              </ul>
            </div> */}
            <div>
              <h4 className="text-sm font-semibold uppercase tracking-wider text-blue-900 mb-4">Resources</h4>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <span className="text-blue-400 cursor-not-allowed">Documentation</span>
                  <span className="ml-2 text-xs bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full">Coming Soon</span>
                </li>
                <li className="flex items-center">
                  <span className="text-blue-400 cursor-not-allowed">API Reference</span>
                  <span className="ml-2 text-xs bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full">Coming Soon</span>
                </li>
                <li className="flex items-center">
                  <span className="text-blue-400 cursor-not-allowed">Blog</span>
                  <span className="ml-2 text-xs bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full">Coming Soon</span>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm font-semibold uppercase tracking-wider text-blue-900 mb-4">Legal Agreements</h4>
              <ul className="space-y-3">
                <li>
                  <a href="https://sulfuric-zephyr-d82.notion.site/Terms-Of-Service-d26ef6c3540e48aa96f3e8c09ccfa342" className="text-blue-600 hover:text-blue-700 transition-colors">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://sulfuric-zephyr-d82.notion.site/Terms-Of-Service-d26ef6c3540e48aa96f3e8c09ccfa342" className="text-blue-600 hover:text-blue-700 transition-colors">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="https://sulfuric-zephyr-d82.notion.site/Terms-Of-Service-d26ef6c3540e48aa96f3e8c09ccfa342" className="text-blue-600 hover:text-blue-700 transition-colors">
                    Refund Policy
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm font-semibold uppercase tracking-wider text-blue-900 mb-4">Contact</h4>
              <ul className="space-y-3">
                <li className="text-blue-700 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                  <a 
                    href="mailto:support@typeless.io" 
                    className="text-blue-600 hover:text-blue-700 transition-colors"
                  >
                    support@typeless.io
                  </a>
                </li>
                <li className="text-blue-700 flex items-center">
                  
                  Typeless Pte. Ltd., 7 Temasek Boulevard, #12-07, Suntec Tower One, Singapore (038987)
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-16 pt-8 border-t border-blue-200 text-center text-blue-600">
            <p>© 2024 Tune AI. All rights reserved. A Typeless Production</p>
          </div>
        </div>
      </footer>

      {enlargedImage && (
        <div className="fixed inset-0 bg-blue-900/90 flex items-center justify-center z-50">
          <div className="relative max-w-[90vw] max-h-[90vh]">
            <button 
              onClick={() => setEnlargedImage('')}
              className="absolute -top-12 right-0 text-white/80 hover:text-white transition-colors"
              aria-label="Close enlarged image"
            >
              <X className="h-8 w-8" />
            </button>
            <img
              src={enlargedImage}
              alt="Enlarged view"
              className="w-full h-full object-contain rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  )
}