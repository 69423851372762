import { db, auth } from '../firebase/config';
import { getAuth } from 'firebase/auth';
export const submitFormHandler =  (formData) => {



  const user = auth.currentUser.uid;
  const email = auth.currentUser.email;
  console.log("User ID:", auth.currentUser);  
  
  
  // Extract form data and prepare final payload
  const finalPayload = {
    // Configuration for the fine-tuning process
    "config_dict": {
      "email": email || null,


      "cpt": formData.hasDocuments || false,
      "cpt_files": formData.ownDocuments || [],
      "cpt_model": formData.cptModel || "unsloth/Llama-3.2-3B-Instruct",//NF
      "PDF_max_retries": 3, //NF
      "PDF_timeout": 60000, //NF
      "PDF_max_seq_length": 2048, //NF
      "PDF_semantic_chunk_size": 500, //NF
      "PDF_chunk_overlap": formData.advancedOptions_CPT?.["Chunk Overlap"] || 50, //NF
      "PDF_openai_embeddings_model": formData.advancedOptions_CPT?.["OpenAI Embeddings Model"] || "text-embedding-3-small", //NF
      "PDF_max_chunk_length": formData.advancedOptions_CPT?.["Max Chunk Length"] || 512, //NF
      "PDF_load_in_4bit": formData.advancedOptions_CPT?.["Load in 4bit"] || true, //NF 
      "PDF_lora_r": formData.advancedOptions_CPT?.["Rank"] || 16, //NF
      "PDF_lora_alpha": formData.advancedOptions_CPT?.["Lora Alpha"]*formData.advancedOptions_CPT?.["Rank"] || 16, //NF
      "PDF_lora_dropout": formData.advancedOptions_CPT?.["Lora Dropout"] || 0, //NF
      "PDF_bias": formData.advancedOptions_CPT?.["Bias"] || "none", //NF
      "PDF_use_gradient_checkpointing": "unsloth",
      "PDF_random_state":42, //O
      "PDF_use_rslora": formData.advancedOptions_CPT?.["Use RSLoRA"] || false, //NF
      "PDF_loftq_config": formData.advancedOptions_CPT?.["LoFTQ Config"] || null, //NF
      "PDF_per_device_train_batch_size": formData.advancedOptions_CPT?.["Per Device Train Batch Size"] || 2, //NF
      "PDF_gradient_accumulation_steps": formData.advancedOptions_CPT?.["Gradient Accumulation Steps"] || 2, //NF
      "PDF_warmup_ratio": formData.advancedOptions_CPT?.["Warmup Ratio"] || 0.1, //NF
      "PDF_num_train_epochs": formData.advancedOptions_CPT?.["Epochs"] || 2, //NF
      "PDF_learning_rate": formData.advancedOptions_CPT?.["Learning Rate"] || 5e-5, //NF
      "PDF_embedding_learning_rate": formData.advancedOptions_CPT?.["Embedding Learning Rate"] || 5e-6, //NF
      "PDF_logging_steps": formData.advancedOptions_CPT?.["Logging Steps"] || 25, //NF
      "PDF_optim": formData.advancedOptions_CPT?.["Optimizer"] || "adamw_8bit", //NF
      "PDF_weight_decay": formData.advancedOptions_CPT?.["Weight Decay"] || 0.0, //NF
      "PDF_lr_scheduler_type": formData.advancedOptions_CPT?.["Learning Rate Scheduler Type"] || "cosine", //NF
      "PDF_seed": formData.advancedOptions_CPT?.["Seed"] || 42, //NF
      "PDF_output_dir": formData.advancedOptions_CPT?.["Output Directory"] || "outputs1", //NF
      "PDF_report_to": formData.advancedOptions_CPT?.["Report To"] || "none", //NF









      "finetuneType": formData.finetuneType || "Not specified",
      "creation_name": formData.creationName || "default_name",
      "name_model": formData.advancedOptions?.model || "mini-instruct",
      "max_seq_length": formData.advancedOptions?.["Max Sequence Length"] || 2048,
      "user_id":user,
      "dtype": null,
      "trainingLocation": formData.trainingLocation || "cloud", 
      "optimizeForSilicon": formData.optimizeForSilicon || "false",
      "modelSaving": formData.modelSaving || "save-hf",
      "load_in_4bit": false,
      "hf_dataset": formData.hfDatasetUrl || formData.selectedDataset || null,
      "save_name": formData.hfRepoName || "Lora_N1",
      "dir_local_save": "./local/",
      "test_size": formData.advancedOptions?.["Evaluation Split"] || 0.005,
      "instruction_col": formData.instructionCol || "instruction",
      "input_col": formData.inputCol || "input",
      "output_col": formData.outputCol || "output",
      "seed": 42,
      "generation_length": formData.advancedOptions?.["Generation Length"] || 1024,
      "HF_TOKEN": formData.hfApiKey || null ,
      "safe_serialization": null,
      "quantization_method": formData.advancedOptions?.["Quantization Method"] || "f16",
      "finish_option": 4,
      "lr_scheduler_type": formData.advancedOptions?.["Learning Rate Scheduler Type"] || "linear",
      "weight_decay": formData.advancedOptions?.["Weight Decay"] || 0.01,
      "optim": formData.advancedOptions?.Optimizer || "adamw_8bit",
      "logging_steps": formData.advancedOptions?.["Logging Steps"] || 25,
      "learning_rate": formData.advancedOptions?.["Learning Rate"] || 0.0002,
      "warmup_steps": 5,
      "num_train_epochs": formData.advancedOptions?.Epochs || 1,
      "save_steps": 25,
      "gradient_accumulation_steps": formData.advancedOptions?.gradient_accumulation_steps || 4,
      "per_device_eval_batch_size": formData.advancedOptions?.per_device_eval_batch_size || 4,
      "per_device_train_batch_size": formData.advancedOptions?.per_device_train_batch_size || 4,
      "packing": false,
      "dataset_num_proc": 2,
      "output_dir": formData.advancedOptions?.output_dir || "outputs"
    },
    // Training-specific configurations
    "training_config": {
      "r": formData.advancedOptions?.["Rank"] || 16,
      "target_modules": ["q_proj", "k_proj", "v_proj", "o_proj", "gate_proj", "up_proj", "down_proj"],
      "lora_alpha": formData.advancedOptions?.["Lora Alpha"]*formData.advancedOptions?.["Rank"] || 16,
      "lora_dropout": 0,
      "bias": "none",
      "use_gradient_checkpointing": formData.advancedOptions?.use_gradient_checkpointing || false,
      "random_state": 42,
      "use_rslora": false,
      "loftq_config": null
    },
    // Evaluation configurations
    "eval_config": {

      "evaluation_dataset_type": formData.evaluationDatasetType || "default_type",
      "evaluation_dataset": formData.Eval_HF_URL || null,
      "generateEvaluationDataset": formData.generateEvaluationDataset || false,
      "evaluation_metrics": {
        "loss": formData.evaluationMetrics?.loss || false,
        "perplexity": formData.evaluationMetrics?.perplexity || false,
        "ai_eval": formData.evaluationMetrics?.aiEval || false
      },//Possibly remove this
      "ai_eval_metrics": formData.aiEvalMetrics || {},
      "openai_api_key": formData.openAiApiKey || "NULL",
      "generate_from_pdf": formData.evaluationMetrics?.GenePDF || false
    },
    // Modal API tokens
    "modal_token_id": formData.modalAccessKey || null,
    "modal_token_secret":  formData.modalSecret || null
  };
  

  // Send the payload
  console.log("Form submitted with data:" , JSON.stringify(finalPayload));
  try {
    fetch( 'https://tunebackend-310229311797.us-central1.run.app/qna_finetuning/SFTIIO' , {
      // 'https://tunebackend-310229311797.us-central1.run.app/qna_finetuning/SFTIIO'
      // http://0.0.0.0:8000/qna_finetuning/SFTIIO
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(finalPayload)
    })

    // navigate("/finetuning-dashboard");


  } catch (error) {
    console.error('Error submitting form:', error);
    return false;
  }






};
