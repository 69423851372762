import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoutButton from './logoutbutton';
import { Plus, Hammer, Database, User, HelpCircle, Menu, X, ChevronRight, Mail } from 'lucide-react';
import { getAuth } from 'firebase/auth';

const publicSidebarItems = [
  { name: "FAQ", icon: HelpCircle, path: "/faq" },
  { name: "Contact Us", icon: Mail, path: "/contact" },
  // Add more public sidebar items here
];

const privateSidebarItems = [
  { name: "Tune Runs", icon: Hammer, path: "/finetuning-dashboard" },
  // Add more private sidebar items here
];

const landingNavItems = [
  { name: "Core Features", href: "#features" },
  { name: "Resources", href: "/resources" },
  { name: "Demo Video", href: "#demo-video" }
];

export default function Header({ 
  showNewTuneButton = false, 
  showDashboardButton = false,
  activeItem,
  onNavItemClick
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === '/finetuning-dashboard';
  const isLandingPage = location.pathname === '/';
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const auth = getAuth();
  
  // Combine public and private items based on auth status
  const sidebarItems = [
    ...publicSidebarItems,
    ...(auth.currentUser ? privateSidebarItems : [])
  ];

  const handleNavClick = (item) => {
    if (!item.disabled) {
      if (item.path) {
        navigate(item.path);
        setIsSidebarOpen(false);
      } else {
        onNavItemClick?.(item.name);
      }
    }
  };

  const handleLandingNavClick = (e, href) => {
    e.preventDefault();
    if (href.startsWith('#')) {
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(href);
    }
  };

  return (
    <>
      {/* Sidebar Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
        <div className="p-4">
          <div className="flex justify-between items-center mb-8">
            <span className="text-xl font-bold text-blue-900">Menu</span>
            <button 
              onClick={() => setIsSidebarOpen(false)}
              className="p-2 hover:bg-blue-50 rounded-lg transition-colors"
            >
              <X className="h-5 w-5 text-blue-900" />
            </button>
          </div>
          <nav className="space-y-2">
            {sidebarItems.map((item) => (
              <button
                key={item.name}
                onClick={() => handleNavClick(item)}
                className={`w-full flex items-center px-4 py-3 text-left rounded-lg hover:bg-blue-50 transition-colors group ${
                  location.pathname === item.path ? 'bg-blue-50 text-blue-700' : ''
                }`}
              >
                {React.createElement(item.icon, {
                  className: `w-5 h-5 mr-3 ${location.pathname === item.path ? 'text-blue-700' : 'text-blue-600'}`,
                })}
                <span className={`${location.pathname === item.path ? 'text-blue-700' : 'text-gray-700'} group-hover:text-blue-900`}>
                  {item.name}
                </span>
                <ChevronRight className={`w-4 h-4 ml-auto ${location.pathname === item.path ? 'text-blue-600' : 'text-gray-400'} group-hover:text-blue-600`} />
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Main Header */}
      <header className="bg-white shadow-sm py-4 sticky top-0 z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-14">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="p-2 -ml-2 mr-2 hover:bg-blue-50 rounded-lg transition-colors"
              >
                <Menu className="h-5 w-5 text-blue-900" />
              </button>
              <div 
                className="flex-shrink-0 flex items-center cursor-pointer" 
                onClick={() => navigate(auth.currentUser ? '/finetuning-dashboard' : '/')}
              >
                <span className="Tune-logo text-xl font-bold">Tune</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              {isLandingPage && (
                <>
                  {landingNavItems.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={(e) => handleLandingNavClick(e, item.href)}
                      className="relative px-3 py-2 rounded-md text-sm font-medium transition-colors text-blue-600 hover:text-blue-800"
                    >
                      <strong>{item.name}</strong>
                    </a>
                  ))}
                  <button
                    onClick={() => window.location.href = "https://noteforms.com/forms/tune-waitlist-rum4ca"}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                  >
                    Join Waitlist
                    <ChevronRight className="ml-1 h-4 w-4" />
                  </button>
                </>
              )}
              {showNewTuneButton && (
                <>
                  <button
                    onClick={() => navigate("/finetune-form")}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                  >
                    <Plus className="w-4 h-4 mr-2" />
                    New Fine-Tuning
                  </button>
                  <div className="border-l border-gray-200 h-6" />
                </>
              )}
              {showDashboardButton && (
                <button
                  onClick={() => navigate("/finetuning-dashboard")}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  Dashboard
                </button>
              )}
              {auth.currentUser && <LogoutButton />}
            </div>
          </div>
        </div>
      </header>
    </>
  );
} 