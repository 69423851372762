import React from 'react';
import { ChevronDown, ChevronUp, ExternalLink, X } from 'lucide-react';
import EvaluationTabs from './EvaluationTabs';
import MetricsTable from './MetricsTable';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Switch,
  IconButton,
  InputAdornment,
  Link,
  Divider,
  Tooltip,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import {

  HelpCircle,
} from "lucide-react";


const RunCard = ({
  runId,
  runData,
  index,
  expandedCard,
  handleCardExpand,
  activeTab,
  setActiveTab,
  activeEvalTab,
  setActiveEvalTab,
}) => {
  console.log(runData[0] , "runData[0]");
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 relative mt-3 mr-3">
      {/* Delete Button */}
      {/* <button
        onClick={(e) => {
          e.stopPropagation();
          // Delete functionality will be added later
        }}
        className="absolute -top-2 -right-2 w-6 h-6 bg-white rounded-full shadow-md flex items-center justify-center border border-gray-200 hover:border-red-200 transition-colors z-10 group"
      >
        <X className="w-3 h-3 text-gray-400 group-hover:text-red-500 transition-colors stroke-[2.5]" />
      </button> */}

      {/* Card Content Wrapper */}
      <div className="overflow-hidden">
        {/* Card Header */}
        <div
          className="p-6 cursor-pointer hover:bg-gray-50 transition-colors"
          onClick={() => handleCardExpand(runId)}
        >
          <div className="flex justify-between items-center">
            <div className="space-y-1.5">
              <div className="flex items-center space-x-3">
                <span className="text-sm font-medium text-gray-500">#{index + 1}</span>
                <h2 className="text-xl font-semibold text-gray-900 leading-tight">
                  {runData[0].name || "Unnamed Job"}
                </h2>
              </div>
              <div className="flex items-center space-x-3 text-sm text-gray-600">
                <span className="text-gray-300">•</span>
                <span className="font-bold">Base Model:</span>
                <span>{runData[0].model}</span>
                <span className="text-gray-300">•</span>
                <span className="font-bold">Status:</span>
                <button className="bg-green-200 bg-opacity-50 text-green-800 px-2 py-1 rounded-lg">
                  {runData[0].status || "SHAY"}
                </button>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              {/* Only show performance improvement if metrics are available AND improvement is not 0 */}
              {!((!runData[0]?.improvement && runData[0]?.improvement !== 0) || 
                 runData[0]?.improvement === 0 ||
                 (runData[0]?.trainLoss === null && runData[0]?.testLoss === null && !runData[0]?.AI_Eval)) ? (
                <div className="text-right">
                  <div className="text-sm font-medium text-gray-600 mb-1">
                    Performance Improvement
                  </div>
                  <div className={`text-2xl font-bold ${
                    runData[0]?.improvement < 0 ? 'text-red-600' : 'text-green-600'
                  }`}>
                    {`${runData[0].improvement < 0 ? '' : '+'}${runData[0].improvement.toFixed(1)}%`}
                  </div>
                  {/* Only show Fine-tuned Score if it's not 0 */}
                  {runData[0]?.AI_Scores && runData[0].AI_Scores !== 0 && (
                    <div className="text-sm text-gray-600 mt-1">
                      Fine-tuned Score: {runData[0].AI_Scores}
                    </div>
                  )}
                  {/* Only show this message when there's no improvement value and no AI_Scores */}
                  {(!runData[0]?.improvement && !runData[0]?.AI_Scores) && (
                    <div className="text-xs text-gray-500 mt-1">
                      (Only If AI Metrics Enabled)
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-right">
                  <div className="text-sm font-medium text-gray-500">
                    No evaluation metrics available
                  </div>
                  <div className="text-xs text-gray-500 mt-1">
                    The method you picked didn't support any standard or AI-based evaluation metrics
                  </div>
                </div>
              )}
              {runData[0].endResult && (
                <a
                  href={runData[0].endResult}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all text-sm font-medium"
                >
                  Get Your Model
                  <ExternalLink className="w-4 h-4 ml-2" />
                </a>
              )}
              {expandedCard === runId ? (
                <ChevronUp className="w-4 h-4 text-gray-400" />
              ) : (
                <ChevronDown className="w-4 h-4 text-gray-400" />
              )}
            </div>
          </div>
        </div>

        {/* Expanded Content */}
        {expandedCard === runId && (
          <div className="border-t border-gray-200">
            {/* Model Access Banner */}
            {runData[0].run_model && (
              <div className="px-6 py-4 bg-green-50 border-y border-green-100">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-green-800 font-medium mb-1">
                      Your fine-tuned model is ready!
                    </h3>
                    <p className="text-green-700 text-sm">
                      Click the button to start using your model in Colab
                    </p>
                  </div>
                  <div>
                    <a
                      href={runData[0].run_model}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-all text-sm font-medium"
                    >
                      Run Model in Colab
                    </a>
                  </div>
                </div>
              </div>
            )}

            {/* Tabs */}
            <div className="px-6 pt-4 border-b border-gray-200">
              <div className="flex space-x-1">
              {/* {["Details", "Metrics"].map((tab) => ( */}
                {["Details"].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setActiveTab(tab.toLowerCase())}
                    className={`px-4 py-2.5 text-sm font-medium rounded-t-lg transition-colors ${
                      activeTab === tab.toLowerCase()
                        ? "bg-white text-blue-600 border-b-2 border-blue-600"
                        : "text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                    }`}
                  >
                    {tab}
                  </button>
                ))}
                {/* Only show Evaluation tab if there's evaluation data */}
                {(runData[0].AI_Eval || 
                  (runData[0].average_new !== null && runData[0].average_old !== null) ||
                  (runData[0].trainLoss !== null && runData[0].testLoss !== null)) && (
                  <button
                    onClick={() => setActiveTab("evaluation")}
                    className={`px-4 py-2.5 text-sm font-medium rounded-t-lg transition-colors relative ${
                      activeTab === "evaluation"
                        ? "bg-white text-blue-600 border-b-2 border-blue-600"
                        : "text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                    }`}
                  >
                    Evaluation
                    <span className="absolute -top-2 -right-2 px-2 py-1 text-xs font-medium bg-yellow-100 text-yellow-800 rounded-full">
                      Beta
                    </span>
                  </button>
                )}
                <button
                  onClick={() => setActiveTab("deployment")}
                  disabled
                  className={`px-4 py-2.5 text-sm font-medium rounded-t-lg transition-colors relative ${
                    activeTab === "deployment"
                      ? "bg-white text-blue-600 border-b-2 border-blue-600"
                      : "text-gray-400 cursor-not-allowed"
                  }`}
                >
                  Deployment
                  <span className="absolute -top-2 -right-2 px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                    Coming Soon
                  </span>
                </button>
              </div>
            </div>

            {/* Content sections */}
            <div className="p-6">
              {/* Details View */}
              {activeTab === "details" && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-gray-50 rounded-xl p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-4">
                      Training Details
                    </h3>
                    <div className="space-y-4">
                      <div>
                        <div className="text-sm font-medium text-gray-600 mb-1">
                          Dataset
                        </div>
                        <div className="text-base font-semibold text-blue-800">
                          {runData[0].dataset || "Custom Dataset"}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-medium text-gray-600 mb-1">
                          Training Location
                        </div>
                        <div className="text-base font-semibold text-blue-800">
                          {runData[0].training_location || "Local"}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-medium text-gray-600 mb-1">
                          Training Time
                        </div>
                        <div className="text-base font-semibold text-blue-800">
                          {runData[0].train_time || "Not specified"}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-medium text-gray-600 mb-1">
                          Model Format
                        </div>
                        <div className="text-base font-semibold text-blue-800">
                          {runData[0].modelSaving || "Standard Format"}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-medium text-gray-600 mb-1">
                          AI Evaluation Metrics
                        </div>
                        <div className="text-base font-medium text-gray-900">
                          {Object.keys(runData[0].AI_Eval || {}).length > 0 ? (
                            Object.keys(runData[0].AI_Eval)
                              .filter(metric => !metric.startsWith('AI_Scores'))
                              .map(metric => (
                                <span key={metric} className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">
                                  {metric}
                                </span>
                              ))
                          ) : (
                            <span className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">None</span>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-medium text-gray-600 mb-1">
                          Standard Evaluation Metrics
                        </div>
                        <div className="text-base font-medium text-gray-900">
                          {runData[0].STD_Eval ? (
                            Object.keys(runData[0].STD_Eval).filter(metric => metric.startsWith('eval_')).map(metric => (
                              <span key={metric} className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">
                                {metric.slice(5)}
                              </span>
                            ))
                          ) : (
                            <span className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">None</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 rounded-xl p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-4">
                      Advanced Settings
                    </h3>
                    <div className="grid grid-cols-2 gap-6">
                      {[
                        {
                          label: "Max Sequence Length",
                          value: runData[0].maxSequenceLength,
                          tooltip: "The maximum number of tokens (words or characters) in each input sequence. Longer lengths allow more context but increase computation."
                        },
                        {
                          label: "Generation Length",
                          value: runData[0].generationLength,
                          tooltip: "The number of tokens the model generates in response to a prompt. Higher values generate longer responses."
                        },
                        {
                          label: "Rank",
                          value: runData[0].rank,
                          tooltip: "Controls the number of trainable parameters in fine-tuning. Smaller values (e.g., 4-8) use fewer resources and are suitable for simpler tasks. Larger values (e.g., 16-32) increase adaptability for complex tasks but require more memory. Default: 8. Adjust based on task complexity and hardware capacity."
                        },
                        {
                          label: "Lora Alpha",
                          value: runData[0].loraAlpha,
                          tooltip: "Determines the strength of fine-tuned weight updates relative to the base model. Choose:\n1 (Default): Balanced for most tasks, including small datasets or simple applications.\n2: Increases adaptation strength, ideal for complex tasks or large datasets requiring stronger fine-tuning. Use cautiously if training becomes unstable."
                        },
                        {
                          label: "Weight Decay",
                          value: runData[0].weightDecay,
                          tooltip: "Controls the penalty applied to model weights during fine-tuning to prevent overfitting. Recommended range: 0.0 - 0.1 (default: 0.01). Use higher values (e.g., 0.01 - 0.1) for smaller datasets to encourage generalization and lower values (e.g., 0.001 - 0.01) for larger datasets. Setting 0.0 disables regularization but may increase the risk of overfitting. Choose carefully based on your dataset size and task."
                        },
                        {
                          label: "Evaluation Split",
                          value: runData[0].evaluationSplit ? `${(Number(runData[0].evaluationSplit) * 100).toFixed(1)}%` : "Not specified",
                          tooltip: "The proportion of data reserved for evaluation. 5% means that 5% of the data is used to evaluate the model."
                        },
                        {
                          label: "Logging Steps",
                          value: runData[0].loggingSteps,
                          tooltip: "Frequency of logging the model's performance metrics. Lower values provide more frequent updates but increase overhead."
                        },
                        {
                          label: "Learning Rate Scheduler Type",
                          value: runData[0].learningRateSchedulerType,
                          tooltip: "Strategy for adjusting the learning rate during training. 'Linear' gradually decreases the rate as training progresses."
                        },
                        {
                          label: "Optimizer",
                          value: runData[0].optimizer,
                          tooltip: "The optimization algorithm used for training. 'adamw_8bit' reduces memory usage and is suitable for large models."
                        },
                        {
                          label: "Learning Rate",
                          value: runData[0].learningRate,
                          tooltip: "Determines the rate of parameter updates during training. Recommended range depends on task and model size. Higher values (e.g., >2e-4) enable faster convergence but risk instability. Lower values (e.g., <2e-4) provide stable, precise updates but may slow down training. Default: 2e-4. Adjust based on your dataset and training stability."
                        },
                        {
                          label: "Epochs",
                          value: runData[0].epochs,
                          tooltip: "The number of times the entire dataset is passed through the model during training. More epochs allow the model to learn more complex patterns but can also lead to overfitting if not monitored."
                        }

                      ].map(setting => (
                        <div key={setting.label} className="bg-white p-4 rounded-lg border border-gray-100 min-h-[100px] flex flex-col">
                          <div className="text-sm font-medium text-gray-600 mb-2 flex items-center">
                            {setting.label}
                            <Tooltip title={setting.tooltip} arrow placement="top">
                              <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                <HelpCircle size={16} />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="text-base font-semibold text-blue-800 mt-auto">
                            {setting.value || "Not specified"}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Metrics View  Removed for now */}
              {/* {activeTab === "metrics" && <MetricsTable run={runData[0]} />} */}

              {/* Evaluation View */}
              {activeTab === "evaluation" && (
                <EvaluationTabs
                  activeEvalTab={activeEvalTab}
                  setActiveEvalTab={setActiveEvalTab}
                  runData={runData}
                />
              )}

              {/* Deployment View */}
              {activeTab === "deployment" && (
                <div className="space-y-6">
                  <div className="bg-gray-50 p-8 rounded-lg text-center">
                    <div className="max-w-2xl mx-auto">
                      <h4 className="text-xl font-semibold text-gray-900 mb-4">
                        Deployment Features Coming Soon
                      </h4>
                      <p className="text-gray-600 mb-6">
                        We're working on powerful deployment features to help you get your models into production. Stay tuned for:
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-left">
                        <div className="bg-white p-4 rounded-lg border border-gray-200">
                          <h5 className="font-medium text-gray-900 mb-2">One-Click Deployment</h5>
                          <p className="text-sm text-gray-600">Deploy your models to popular cloud platforms with just one click.</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg border border-gray-200">
                          <h5 className="font-medium text-gray-900 mb-2">Multiple Export Formats</h5>
                          <p className="text-sm text-gray-600">Export your models in various formats including ONNX, TensorRT, and more.</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg border border-gray-200">
                          <h5 className="font-medium text-gray-900 mb-2">API Integration</h5>
                          <p className="text-sm text-gray-600">Instantly create API endpoints for your models with automatic documentation.</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg border border-gray-200">
                          <h5 className="font-medium text-gray-900 mb-2">Monitoring & Analytics</h5>
                          <p className="text-sm text-gray-600">Track your model's performance and usage in production environments.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RunCard; 