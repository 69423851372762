import React, { useState } from 'react';
import { ChevronRight, PlayCircle, ChevronLeft, ChevronDown } from 'lucide-react';
import Header from './ui/Header';

// Import images
import datasetPreparationImage from '../faq-Images/dataset-preparation.jpg';
import dataFormattingImage from '../faq-Images/data-formatting.jpg';
import datasetStructureImage from '../faq-Images/dataset-structure.jpg';
import dataValidationImage from '../faq-Images/data-validation.jpg';
import datasetReviewImage from '../faq-Images/dataset-review.jpg';
import modelConfigImage from '../faq-Images/model-config.jpg';
import trainingProgressImage from '../faq-Images/training-progress.jpg';
import modelEvaluationImage from '../faq-Images/model-evaluation.jpg';
import finalResultsImage from '../faq-Images/final-results.jpg';

// Add new image imports
import finetuneStep1 from '../faq-Images/photo_1_2025-02-11_20-46-07.jpg';
import finetuneStep2 from '../faq-Images/photo_2_2025-02-11_20-46-07.jpg';
import finetuneStep3 from '../faq-Images/photo_3_2025-02-11_20-46-07.jpg';
import finetuneStep4 from '../faq-Images/photo_4_2025-02-11_20-46-07.jpg';
import finetuneStep5 from '../faq-Images/photo_5_2025-02-11_20-46-07.jpg';
import finetuneStep6 from '../faq-Images/photo_6_2025-02-11_20-46-07.jpg';
import finetuneStep7 from '../faq-Images/photo_7_2025-02-11_20-46-07.jpg';
import finetuneStep8 from '../faq-Images/photo_8_2025-02-11_20-46-07.jpg';
import finetuneStep9 from '../faq-Images/photo_9_2025-02-11_20-46-07.jpg';
import finetuneStep10 from '../faq-Images/photo_10_2025-02-11_20-46-07.jpg';
import finalStep from '../faq-Images/photo_5_2025-02-11_20-46-21.jpg';
import tuneVideo from '../faq-Images/tuneVideo.mp4';

const videoTutorials = [
  {
    title: "Getting Started with Tune",
    description: "Learn how to create your first fine-tuning run",
    videoUrl: "https://drive.google.com/file/d/1E0ySc2jrD_61Iq9QtTbpZ16YawlxIzOp/preview"
  },
  // Add more video tutorials here
];

// Tutorial images array
const tutorialImages = [
  {
    src: dataFormattingImage,
    caption: "Step 1: Getting Started with Dataset Preparation"
  },
  {
    src: datasetPreparationImage,
    caption: "Step 2: Formatting Your Data"
  },
  {
    src: datasetStructureImage,
    caption: "Step 3: Dataset Structure"
  },
  {
    src: dataValidationImage,
    caption: "Step 4: Data Validation"
  },
  {
    src: datasetReviewImage,
    caption: "Step 5: Dataset Review"
  },
  {
    src: modelConfigImage,
    caption: "Step 6: Fine-tuning Configuration"
  },
  {
    src: trainingProgressImage,
    caption: "Step 7: Training Progress"
  },
  {
    src: modelEvaluationImage,
    caption: "Step 8: Model Evaluation"
  },
  {
    src: finalResultsImage,
    caption: "Step 9: Final Results"
  }
];

// Add new fine-tuning tutorial images array before the faqItems
const finetuningTutorialImages = [
  {
    src: finetuneStep8,
    caption: "Getting Started: Click 'New Fine-Tuning' to begin your fine-tuning journey. This will start the step-by-step configuration process."
  },
  {
    src: finetuneStep3,
    caption: "Step 1 - Document Selection: Choose whether you want to fine-tune using your own documents. Select 'Yes' to upload PDFs/Word files (max 10MB each) or 'No' to use pre-built datasets."
  },
  {
    src: finetuneStep10,
    caption: "Step 2 - Purpose Selection: Choose your fine-tuning purpose:\n• Question Answering: For creating Q&A systems\n• Summarization: For document summarization\n• Sentiment Analysis: For emotion/opinion analysis"
  },
  {
    src: finetuneStep4,
    caption: "Step 3 - Data Source: Select between:\n• Provided datasets (optimized for each task)\n• Your own Hugging Face dataset (requires API key)\n• Upload your own dataset (coming soon)"
  },
  {
    src: finetuneStep6,
    caption: "Step 4 - Training Location: Choose where to run your training:\n• Cloud (Modal): For powerful cloud computing\n• Local Training: For your own machine (coming soon)\nRequires Modal credentials for cloud option."
  },
  {
    src: finetuneStep5,
    caption: "Step 5 - Model Saving: Select how to save your model:\n• Save to Hugging Face (safetensors)\n• Push to Hugging Face (GGUF format)\n• Download in GGUF format\n• Download model + adapter"
  },
  {
    src: finetuneStep7,
    caption: "Step 6 - Evaluation Setup: Configure testing:\n• Choose evaluation dataset\n• Use existing data or generate new\n• Set up synthetic data generation\n• Configure validation parameters"
  },
  {
    src: finalStep,
    caption: "Final Step - Review & Submit: Review all settings and submit your fine-tuning job:\n• Verify model configuration\n• Check training parameters\n• Confirm evaluation setup\n• Submit to start training"
  }
];

// Add step-specific instructions
const finetuningStepInstructions = {
  0: `Document Upload:
   - Choose 'Yes' if you have PDFs or Word documents to train on
   - Select 'No' to use our pre-built datasets
   - Drag and drop or click to upload files (max 10MB each)
   - View uploaded files and manage them in the list below`,

  1: `Purpose Selection:
   - Choose between Question Answering, Summarization, or Sentiment Analysis
   - Question Answering: For creating Q&A systems
   - Summarization: For generating concise summaries
   - Sentiment Analysis: For analyzing text emotions
   - Each purpose optimizes the model differently`,

  2: `Data Source:
   - Choose from our curated datasets (optimized per task)
   - Use your Hugging Face dataset (requires API key)
   - For Hugging Face datasets:
     • Provide dataset URL/name
     • Configure input/output columns
     • Set up instruction format`,

  3: `Training Location:
   - Cloud (Modal): 
     • Powerful cloud computing
     • Requires Modal credentials
     • Optimized for performance
   - Local training (coming soon):
     • Run on your own machine
     • GPU/Silicon optimization`,

  4: `Model Saving Format:
   - Safetensors: Efficient storage format
   - GGUF Format: 
     • Compatible with Ollama
     • Ideal for local deployment
   - Download with adapter: For custom implementations
   - VLLM Implementation (coming soon)`,

  5: `Advanced Configuration:
   - Model Selection: Choose base model
   - Sequence Settings:
     • Max length for inputs
     • Generation length for outputs
   - Training Parameters:
     • Learning rate and scheduler
     • LoRA rank and alpha
     • Weight decay and epochs
   - PDF-specific settings available`,

  6: `Evaluation Setup:
   - Choose evaluation method:
     • Use existing training data
     • Generate new evaluation data
   - For document-based models:
     • Enable synthetic data generation
     • Configure test data split
   - Set up validation parameters`,

  7: `AI Evaluation Options:
   - Enable AI-powered metrics:
     • G-Eval: General performance
     • Summarization quality check
     • Answer relevancy testing
     • Bias and toxicity detection
   - Requires OpenAI API key`,

  8: `Standard Metrics:
   - Loss tracking (always enabled)
   - Monitor training progress
   - Track model performance
   - View learning effectiveness`,

  9: `Final Review:
   - Name your fine-tuned model
   - Review all configurations:
     • Model parameters
     • Training settings
     • Evaluation setup
   - Confirm and submit`
};

const faqItems = [
  {
    title: "Video Tutorials",
    items: videoTutorials.map(video => ({
      question: video.title,
      answer: video.description,
      video: video.videoUrl
    }))
  },
  {
    title: "Getting Started with Tune",
    items: [
      {
        question: "What data formats are supported?",
        answer: "Tune supports the following data formats:\n\n1. Document Formats:\n- PDF documents\n- Word documents\n\n2. Hugging Face Datasets:\n- Must follow the format specified in the fine-tuning form\n- Requires proper configuration of input/output columns\n\nEnsure your data follows our formatting guidelines for optimal results."
      }
    ]
  },
  {
    title: "How to Tune?",
    items: [
      {
        question: "How to use Tune for fine-tuning?",
        answer: "Watch this comprehensive guide on how to use Tune for fine-tuning your models.",
        video: tuneVideo
      }
    ]
  },
  {
    title: "Evaluation & Deployment",
    items: [
      {
        question: "How can I evaluate my model's performance?",
        answer: "Tune offers comprehensive evaluation tools:\n\n1. Default Metrics:\n   - Training vs Test Loss tracking\n   - Performance graphs and metrics\n\n2. AI Evaluation (Available for specific fine-tuning options):\n   - Automated response quality assessment\n   - Comparative analysis with base model\n   - Detailed performance insights\n\nAll metrics are easily accessible through your dashboard."
      },
      {
        question: "How do I use my fine-tuned model?",
        answer: "Your fine-tuned model will be compatible with:\n\n1. Jan AI\n2. Ollama\n3. GPT4ALL\n\nDetailed implementation guides are provided for each platform to help you get started with your fine-tuned model."
      }
    ]
  }
];

export default function FAQ() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    '0-0': true  // This will open the first item (index 0) in the first section (index 0)
  });
  const [currentFinetuningSlide, setCurrentFinetuningSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % tutorialImages.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + tutorialImages.length) % tutorialImages.length);
  };

  const handleZoomClick = (e) => {
    e.stopPropagation();
    setIsZoomed(true);
  };

  const toggleSection = (sectionIndex, itemIndex) => {
    setExpandedSections(prev => ({
      ...prev,
      [`${sectionIndex}-${itemIndex}`]: !prev[`${sectionIndex}-${itemIndex}`]
    }));
  };

  const nextFinetuningSlide = () => {
    setCurrentFinetuningSlide((prev) => (prev + 1) % finetuningTutorialImages.length);
  };

  const prevFinetuningSlide = () => {
    setCurrentFinetuningSlide((prev) => (prev - 1 + finetuningTutorialImages.length) % finetuningTutorialImages.length);
  };

  const renderSlideshow = (item) => {
    const images = item.customSlideshow === "finetuning" ? finetuningTutorialImages : tutorialImages;
    const currentSlideIndex = item.customSlideshow === "finetuning" ? currentFinetuningSlide : currentSlide;
    const nextSlideFunc = item.customSlideshow === "finetuning" ? nextFinetuningSlide : nextSlide;
    const prevSlideFunc = item.customSlideshow === "finetuning" ? prevFinetuningSlide : prevSlide;
    const setSlideFunc = item.customSlideshow === "finetuning" ? setCurrentFinetuningSlide : setCurrentSlide;

    return (
      <div className="pl-7 mt-4">
        {item.customSlideshow === "finetuning" && (
          <div className="mb-4 bg-white p-4 rounded-lg border border-blue-200">
            <pre className="whitespace-pre-wrap text-blue-700 font-mono text-sm">
              {finetuningStepInstructions[currentSlideIndex]}
            </pre>
          </div>
        )}
        <div className="relative bg-blue-50 p-4 rounded-lg border border-blue-200">
          <div 
            className="aspect-[16/9] relative overflow-hidden rounded-lg"
          >
            <img
              src={images[currentSlideIndex].src}
              alt={images[currentSlideIndex].caption}
              className="w-full h-full object-contain"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
              <p className="text-center">{images[currentSlideIndex].caption}</p>
            </div>
          </div>
          <div className="absolute top-1/2 transform -translate-y-1/2 left-6">
            <button
              onClick={(e) => {
                e.stopPropagation();
                prevSlideFunc();
              }}
              className="bg-white rounded-full p-2 shadow-md hover:bg-blue-50 transition-colors"
            >
              <ChevronLeft className="w-6 h-6 text-blue-600" />
            </button>
          </div>
          <div className="absolute top-1/2 transform -translate-y-1/2 right-6">
            <button
              onClick={(e) => {
                e.stopPropagation();
                nextSlideFunc();
              }}
              className="bg-white rounded-full p-2 shadow-md hover:bg-blue-50 transition-colors"
            >
              <ChevronRight className="w-6 h-6 text-blue-600" />
            </button>
          </div>
          <div className="mt-4 flex justify-center space-x-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  setSlideFunc(index);
                }}
                className={`w-2 h-2 rounded-full ${
                  currentSlideIndex === index ? 'bg-blue-600' : 'bg-blue-200'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-blue-50">
      <Header />
      
      <main className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-blue-900 mb-8">Frequently Asked Questions</h1>
          
          <div className="space-y-12">
            {faqItems.map((section, sectionIndex) => (
              <div key={sectionIndex} className="bg-white rounded-xl shadow-sm p-6 border border-blue-100">
                <h2 className="text-xl font-semibold text-blue-900 mb-6">{section.title}</h2>
                
                <div className="space-y-8">
                  {section.items.map((item, itemIndex) => (
                    <div key={itemIndex} className="space-y-4">
                      <button 
                        onClick={() => toggleSection(sectionIndex, itemIndex)}
                        className="w-full text-left"
                      >
                        <h3 className="text-lg font-medium text-blue-800 flex items-center justify-between">
                          <span className="flex items-center">
                            {section.title === "Video Tutorials" ? (
                              <PlayCircle className="w-5 h-5 mr-2 text-blue-600" />
                            ) : (
                              <ChevronRight className="w-5 h-5 mr-2 text-blue-600" />
                            )}
                            {item.question}
                          </span>
                          <ChevronDown 
                            className={`w-5 h-5 text-blue-600 transform transition-transform duration-200 ${
                              expandedSections[`${sectionIndex}-${itemIndex}`] ? 'rotate-180' : ''
                            }`}
                          />
                        </h3>
                      </button>
                      
                      {expandedSections[`${sectionIndex}-${itemIndex}`] && (
                        <>
                          <p className="text-blue-700 whitespace-pre-line pl-7">
                            {item.answer}
                          </p>

                          {item.slideshow && renderSlideshow(item)}

                          {item.video && (
                            <div className="pl-7 mt-4">
                              <div className="aspect-video rounded-lg overflow-hidden border border-blue-200">
                                <iframe
                                  src={item.video}
                                  allow="autoplay"
                                  className="w-full h-full"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
} 